import { Paragraph } from 'components/Typography'
import AdditionalDetails from './AdditionalDetails'
import { useContext, useMemo } from 'react'
import ApplicationFormContext from './ApplicationFormContext'
import { boldedListHtml } from './helper'
import RecommendedHeading from './RecommendedHeading'

export default function GoalAdditionalDetails(): JSX.Element {
  const { formData, goalCategories, onGoalEdit } = useContext(
    ApplicationFormContext,
  )

  const goals = useMemo(() => {
    const goalCategoryTags = goalCategories.map((tag) => tag.name)
    const top = []
    const important = []
    const notPrioritized = []

    goalCategoryTags.forEach((goalCategoryTag) => {
      Object.entries(formData).forEach(([key, value]) => {
        if (key.startsWith(goalCategoryTag.toLowerCase())) {
          console.log(key, value)
          const readableTagName = key
            .replace(goalCategoryTag.toLowerCase() + '-', '')
            .replace(/-/g, ' ')
            .replace(/\b\w/g, (char) => char.toLowerCase())
          console.log(readableTagName)
          if (value === 3) {
            top.push(readableTagName)
          } else if (value === 2) {
            important.push(readableTagName)
          } else if (value === 1 || value === 0) {
            notPrioritized.push(readableTagName)
          }
        }
      })
    })

    const boldedTop = boldedListHtml(top, true)
    const boldedImportant = boldedListHtml(important, true)
    const boldedNotPrioritized = boldedListHtml(notPrioritized)

    const notPriotity = (() => {
      switch (notPrioritized.length) {
        case 0:
          return null
        case 1:
          // if the last word ends with 's', it is plural. i.e. 'technical skills'
          if (notPrioritized[0].endsWith('s')) {
            return `<p>Currently, ${boldedNotPrioritized} are <u>not key priorities or focus areas</u> for you</p>`
          }
          return `<p>Currently, ${boldedNotPrioritized} is <u>not a key priority or focus area</u> for you</p>`
        default:
          return `<p>Currently, ${boldedNotPrioritized} are <u>not key priorities or focus areas</u> for you</p>`
      }
    })()

    return [
      top.length > 0
        ? `<p>${boldedTop} are your <u>top areas of growth</u></p>`
        : null,
      important.length > 0
        ? `<p>${boldedImportant} are <u>also important</u> to you</p>`
        : null,
      notPriotity,
    ]
  }, [formData])

  const summaryDescription = useMemo(() => {
    const goalCategoryTags = goalCategories.map((tag) => tag.name)
    const boldedStrengthCategories = boldedListHtml(
      goalCategoryTags,
      false,
      true,
    )
    if (goalCategoryTags.length === 0) {
      return null
    }

    return `<p>You’ve identified your areas of growth across key skills in ${boldedStrengthCategories}. Here’s a summary based on your selections:</p>`
  }, [])

  const summaryList = useMemo(() => {
    const list = goals
      .filter((item) => !!item)
      .map((itemHtml, index) => (
        <div
          key={index}
          dangerouslySetInnerHTML={{ __html: itemHtml as string }}></div>
      ))
    return list
  }, [goals])

  return (
    <AdditionalDetails
      title={
        <RecommendedHeading>
          What else should we know about your goals and areas of growth?
        </RecommendedHeading>
      }
      description={
        <Paragraph>
          This could include specific skills, priorities, or insights that would
          help us better understand your goals. Take a moment to{' '}
          <b>reflect on the summary below.</b>
        </Paragraph>
      }
      name="goals-notes"
      summaryProps={{
        title: 'Summary of your areas of growth',
        description: (
          <div
            dangerouslySetInnerHTML={{
              __html: summaryDescription as string,
            }}></div>
        ),
        list: summaryList,
        onEdit: onGoalEdit,
      }}
    />
  )
}
