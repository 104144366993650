import { ReactNode } from 'react'

export interface IRecommendedHeadingProps {
  recommendedText?: string
  children: ReactNode
}

export default function RecommendedHeading({
  recommendedText = 'RECOMMENDED',
  children,
}: IRecommendedHeadingProps): JSX.Element {
  return (
    <h2>
      {children} <mark className="recommended">({recommendedText})</mark>
    </h2>
  )
}
