import { useCallback, useEffect, useMemo, useState } from 'react'
import EssentialDetails from './EssentialDetails'
import AdditionalDetails from './AdditionalDetails'
import ImportantConsiderations from './ImportantConsiderations'
import LEADR from './LEADR'
import TopSkill from './TopSkill'
import LifeExperiences from './LifeExperiences'
import { TExpertiseOption, Tag, TagCategory } from './types'
import { IOption } from 'components/Inputs/Select'
import BoostPrioritization from './BoostPrioritization'
import useMediaQuery from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'
import _ from 'lodash'
import axios from 'axios'
import ManagerApproval from './ManagerApproval'
import StrengthAdditionalDetails from './StrengthAdditionalDetails'
import GoalAdditionalDetails from './GoalAdditionalDetails'

function unrollUserResults(data) {
  const rows = []

  if (data.rows && data.rows.length) {
    data.rows.forEach((row) => {
      if (row.data && row.data.id) {
        rows.push(row.data)
      }
    })
  }

  return rows
}

interface MentorshipApplicationValues {
  steps: any[]
  currentStep: number
  onBackStep: () => void
  onStrengthEdit: () => void
  onGoalEdit: () => void
  onSelfBoostEdit: () => void
  updateRawFormData: (newFormData: FormData) => void
  incrementStep: () => void
  setStrengthCategories: (categories: TagCategory[]) => void
  strengthCategories: TagCategory[]
  setGoalCategories: (categories: TagCategory[]) => void
  goalCategories: TagCategory[]
  categories: TagCategory[]
  leadrCharacteristics: TagCategory[]
  formData: any
  setErrors: (errors: Record<string, string[]>) => void
  getErrors: () => Record<string, string[]>
  boostList: IOption[]
  seniorityList: IOption[]
  isMobile?: boolean
  loadMangerOptions: (
    inputValue: string,
    callback: (options: any) => void,
  ) => void
}

export default function useMentorshipApplication(
  tag_categories: TagCategory[],
  tags: Tag[],
  boostList: IOption[],
  expertiseList: TExpertiseOption[],
  seniorityList: IOption[],
  userData: {
    locations: any[]
    departments: any[]
  },
  usersUrl: string,
  requiresManager: boolean,
  requiresBudget: boolean,
): MentorshipApplicationValues {
  const [steps, setSteps] = useState([])
  const [currentStep, setCurrentStep] = useState(0)
  const [rawFormData] = useState(new FormData())
  const [strengthCategories, setStrengthCategories] = useState<TagCategory[]>(
    [],
  )
  const [goalCategories, setGoalCategories] = useState<TagCategory[]>([])
  const [formData, setFormData] = useState({})

  const leadr_characteristics = useMemo(
    () =>
      tag_categories.filter((category) => category.name !== 'life experience'),
    [],
  )
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)

  const updateRawFormData = useCallback(
    (newFormData: FormData) => {
      for (const pair of newFormData.entries()) {
        rawFormData.append(pair[0], pair[1])
      }

      const newObject = Array.from(newFormData.entries()).reduce(
        (acc, [key, value]) => {
          try {
            acc[key] = JSON.parse(value.toString())
          } catch (error) {
            acc[key] = value
          }
          return acc
        },
        {},
      )

      setFormData({ ...formData, ...newObject })
      return { ...formData, ...newObject }
    },
    [formData, setFormData, rawFormData],
  )

  const incrementStep = useCallback(() => {
    setCurrentStep(currentStep + 1)
    window.scrollTo(0, 0)
  }, [setCurrentStep, currentStep])

  const onBackStep = useCallback(() => {
    setCurrentStep(currentStep - 1)
    window.scrollTo(0, 0)
  }, [setCurrentStep, currentStep])

  /**
   * Takes the current form data and saves it to rawFormData in the context
   */
  const saveFormData = useCallback(() => {
    const form = document.getElementById('mentorship_application_form')
    const newFormData = new FormData(form as HTMLFormElement)
    updateRawFormData(newFormData)
  }, [updateRawFormData])

  /**
   * When user is at StrengthAdditionalDetails step, we want to let them edit their strengths
   * this will take them back to the first strength page
   */
  const onStrengthEdit = useCallback(() => {
    saveFormData()
    setCurrentStep(4)
    window.scrollTo(0, 0)
  }, [setCurrentStep, saveFormData])

  /**
   * When user is at GoalAdditionalDetails step, we want to let them edit their goals
   * this will take them back to the first goal page
   */
  const onGoalEdit = useCallback(() => {
    saveFormData()
    setCurrentStep(8)
    window.scrollTo(0, 0)
  }, [setCurrentStep, saveFormData])

  /**
   * When user is at BoostPrioritization step, we want to let them edit their preferences
   */
  const onSelfBoostEdit = useCallback(() => {
    saveFormData()
    setCurrentStep(2)
    window.scrollTo(0, 0)
  }, [setCurrentStep, saveFormData])

  /**
   * Sets the what step we should show user next based on their answers from LEADR step
   * We want to show them their first two LEADR as their strengths, then show them the last
   * three LEADR in reverse order as their goals. The last in LEADR should be their
   * top goal.
   */
  useEffect(() => {
    const lifeExperienceCategoryId = tag_categories.find(
      (category) => category.name === 'life experience',
    )

    const defaultSteps = [
      <EssentialDetails
        key="essential-details"
        expertiseList={expertiseList}
      />,
      <ImportantConsiderations key="important-considerations" />,
      <LEADR key="leadr" />,
    ]

    let steps = defaultSteps

    if (!!strengthCategories.length && !!goalCategories.length) {
      steps = [
        ...defaultSteps,
        ...strengthCategories.map((strength_category, index) => (
          <TopSkill
            isFirst={index === 0}
            skill={strength_category.name}
            skillTags={tags.filter(
              (tag) => tag.category_id === strength_category.id,
            )}
            key={`top-skill-strength-${index}`}
          />
        )),
        <LifeExperiences
          key="life-experiences"
          lifeExperiences={tags.filter(
            (tag) => tag.category_id === lifeExperienceCategoryId.id,
          )}
        />,
        <StrengthAdditionalDetails key="additional-details-strengths" />,
        ...goalCategories.map((goal_category, index) => (
          <TopSkill
            isFirst={index === 0}
            skill={goal_category.name}
            skillTags={tags.filter(
              (tag) => tag.category_id === goal_category.id,
            )}
            isGoal
            key={`top-skill-goal-${index}`}
          />
        )),
        <LifeExperiences
          key="life-experiences"
          lifeExperiences={tags.filter(
            (tag) => tag.category_id === lifeExperienceCategoryId.id,
          )}
          isGoal
        />,
        <GoalAdditionalDetails key="additional-details-goals" />,
        <BoostPrioritization
          key="boost-prioritiztion"
          name="boosted-field"
          boostList={boostList}
          expertiseList={expertiseList}
          seniorityList={seniorityList}
          userData={userData}
        />,
        <AdditionalDetails
          key="additional-details-overall"
          title={<h2>Anything else you&apos;d like to share?</h2>}
          name="overall-notes"
        />,
      ]
    } else {
      steps = defaultSteps
    }

    if (requiresManager) {
      steps.push(
        <ManagerApproval
          key="manager-approval"
          requiresBudget={requiresBudget}
        />,
      )
    }

    setSteps(steps)
  }, [strengthCategories, goalCategories, requiresManager])

  const [errors, setErrors] = useState<Record<string, string[]>>({})
  const getErrors = () => errors

  const loadMangerOptions = useCallback(
    _.debounce((inputValue, callback) => {
      axios
        .get(`${usersUrl}.json`, {
          params: {
            'filter[*]': inputValue,
            per_page: !!inputValue ? undefined : 8,
            active_only: true,
            exclude_self: true,
          },
        })
        .then(({ data: { data } }) => {
          callback(unrollUserResults(data))
        })
    }, 400),
    [usersUrl],
  )

  return {
    steps,
    currentStep,
    onBackStep,
    onStrengthEdit,
    onGoalEdit,
    onSelfBoostEdit,
    updateRawFormData,
    incrementStep,
    setStrengthCategories,
    strengthCategories,
    setGoalCategories,
    goalCategories,
    categories: tag_categories,
    leadrCharacteristics: leadr_characteristics,
    formData,
    setErrors,
    getErrors,
    boostList,
    seniorityList,
    isMobile,
    loadMangerOptions,
  }
}
