import Modal, { IModalProps, Size } from 'components/Modal'
import Input from 'components/Input'
import { IOption } from 'components/Inputs/Select'
import Container from 'components/Container'
import Paragraph from 'components/Typography/Paragraph'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/react'
import { useMentorshipExchangeDetailsContext } from 'components/MentorshipExchangeDetails/MentorshipExchangeDetailsContext'
import { spacings } from 'stylesheets/theme'

interface IParticipantData {
  participant_1?: string
  participant_1_location?: string
  participant_1_industry_years?: string
  participant_1_boost?: string
  participant_1_boost_choice?: string
  participant_2?: string
  participant_2_location?: string
  participant_2_industry_years?: string
  participant_2_boost?: string
  participant_2_boost_choice?: string
}
interface IRejectModalProps
  extends Omit<
    IModalProps,
    'size' | 'children' | 'submitButton' | 'cancelButton' | 'title' | 'onSave'
  > {
  possibleBoostOptions: IOption[]
  participantData: IParticipantData
  onReject: (
    boosts: Record<string, unknown>,
    resetAllBoosts: () => void,
  ) => void
}

const fieldCss = css({
  width: '100%',
})

const inputContainerStyle = css({
  marginTop: spacings.grid_gap_basis_num * 2,
  gap: spacings.grid_gap_basis_num * 2,
})

export default function RejectConfirmationModal({
  ...props
}: IRejectModalProps): JSX.Element {
  const { seniorityList, expertiseList, userData } =
    useMentorshipExchangeDetailsContext()

  const [boostField1, setBoostField1] = useState<string>(
    props?.participantData?.participant_1_boost || null,
  )
  const [boostFieldChoice1, setBoostedFieldChoice1] = useState<string>(
    props?.participantData?.participant_1_boost_choice || null,
  )

  const [boostField2, setBoostField2] = useState<string>(
    props?.participantData?.participant_2_boost || null,
  )
  const [boostFieldChoice2, setBoostedFieldChoice2] = useState<string>(
    props?.participantData?.participant_2_boost_choice || null,
  )

  const [boosts, setBoosts] = useState<Record<string, unknown>>({
    partner1Boost: '',
    partner2Boost: '',
  })

  const getLocationValue = (locationLabel: string) => {
    return (
      locationOptions.find((location) => location.label == locationLabel)
        ?.value ?? ''
    )
  }

  // We need to set boost choices to the value of location option in order for dropdown to show the correct value
  useEffect(() => {
    const participant_1_boost = props?.participantData?.participant_1_boost
    const participant_1_boost_choice =
      props?.participantData?.participant_1_boost_choice
    const participant_2_boost = props?.participantData?.participant_2_boost
    const participant_2_boost_choice =
      props?.participantData?.participant_2_boost_choice
    if (
      participant_1_boost == 'important-location' &&
      !!participant_1_boost_choice
    ) {
      const locationChoice = getLocationValue(participant_1_boost_choice)
      setBoostedFieldChoice1(locationChoice)
    }

    if (
      participant_2_boost == 'important-location' &&
      !!participant_2_boost_choice
    ) {
      const locationChoice = getLocationValue(participant_2_boost_choice)
      setBoostedFieldChoice2(locationChoice)
    }
  }, [
    props?.participantData?.participant_1_boost,
    props?.participantData?.participant_2_boost,
    props?.participantData?.participant_1_boost_choice,
    props?.participantData?.participant_2_boost_choice,
  ])

  const resetAllBoosts = useCallback(() => {
    setBoostField1(props?.participantData?.participant_1_boost || null)
    setBoostedFieldChoice1(
      props?.participantData?.participant_1_boost_choice || null,
    )
    setBoostField2(props?.participantData?.participant_2_boost || null)
    setBoostedFieldChoice2(
      props?.participantData?.participant_2_boost_choice || null,
    )
  }, [props.participantData])

  const onChangeBoost1 = (input: Record<string, any>) => {
    setBoostField1(input['partner1Boost'])
    setBoostedFieldChoice1(null)
    setBoosts({ ...boosts, ...input, partner1BoostChoice: null })
  }

  const onChangeBoostChoice1 = (input: Record<string, any>) => {
    setBoostedFieldChoice1(input['partner1BoostChoice'])
    const boostChoice = getBoostChoice(
      boostField1,
      input['partner1BoostChoice'],
    )
    setBoosts({ ...boosts, partner1BoostChoice: boostChoice })
  }

  const onChangeBoost2 = (input: Record<string, any>) => {
    setBoostField2(input['partner2Boost'])
    setBoostedFieldChoice2(null)
    setBoosts({ ...boosts, ...input, partner2BoostChoice: null })
  }

  const onChangeBoostChoice2 = (input: Record<string, any>) => {
    setBoostedFieldChoice2(input['partner2BoostChoice'])
    const boostChoice = getBoostChoice(
      boostField2,
      input['partner2BoostChoice'],
    )
    setBoosts({ ...boosts, partner2BoostChoice: boostChoice })
  }

  const getBoostChoice = (boostField, boostChoiceValue) => {
    if (boostField === 'important-location') {
      // If the boostField is "Location", we want to save partner1BoostChoice/partner2BoostChoice to location option.label i.e. "Boston, MA"
      const locationChoice = locationOptions.find(
        (location) => location.value == boostChoiceValue,
      )?.label
      return locationChoice
    } else if (boostField === 'important-business-unit') {
      const departmentChoice = departmentOptions.find(
        (department) => department.value == boostChoiceValue,
      )?.label
      return departmentChoice
    } else {
      return boostChoiceValue
    }
  }

  useEffect(() => {
    setBoosts({
      partner1Boost: props?.participantData?.participant_1_boost ?? '',
      partner2Boost: props?.participantData?.participant_2_boost ?? '',
    })
  }, [props.participantData])

  const onSave = () => {
    props.onReject(boosts, resetAllBoosts)
  }

  const participant1Name = useMemo(() => {
    return props?.participantData?.participant_1 ?? 'Partner #1'
  }, [props.participantData])
  const participant2Name = useMemo(() => {
    return props?.participantData?.participant_2 ?? 'Partner #2'
  }, [props.participantData])

  const showSpecificBoostInput = (boostField: string) => {
    switch (boostField) {
      case 'important-tenure':
      case 'important-location':
      case 'important-expertise':
      case 'important-business-unit':
        return true
      default:
        return false
    }
  }

  const showSpecificBoostInput1 = useMemo(
    () => showSpecificBoostInput(boostField1),
    [boostField1],
  )
  const showSpecificBoostInput2 = useMemo(
    () => showSpecificBoostInput(boostField2),
    [boostField2],
  )

  const locationOptions = useMemo(() => {
    return userData?.locations.map((location) => ({
      value: location,
      label: [location.city, location.state].filter((l) => !!l).join(', '),
    }))
  }, [userData])

  const departmentOptions = useMemo(() => {
    return userData?.departments.map((department) => ({
      value: department,
      label: department,
    }))
  }, [userData])

  const disableConfirmationButton = useMemo(
    () =>
      (showSpecificBoostInput(boostField1) && !boostFieldChoice1) ||
      (showSpecificBoostInput(boostField2) && !boostFieldChoice2),
    [boostField1, boostField2, boostFieldChoice1, boostFieldChoice2],
  )

  const getSpecificBoostOptions = (
    boostField: string,
    industry_years: string,
  ) => {
    switch (boostField) {
      case 'important-tenure':
        // If participant only has 0-2 industry years (min), we only want to show options that have higher seniority
        if (industry_years == '0-2') {
          return seniorityList.filter(
            (seniority) => parseInt(seniority.value) >= 3,
          )
          // If participant has 20+ industry years (max), we want to show options that have lower seniority
        } else if (industry_years == '20+') {
          return seniorityList.filter(
            (seniority) => parseInt(seniority.value) <= 3,
          )
        } else {
          return seniorityList
        }
      case 'important-location':
        return locationOptions
      case 'important-expertise':
        return expertiseList
      case 'important-business-unit':
        return departmentOptions
      default:
        return []
    }
  }

  const specificBoostInputLabel = (boost: string) => {
    switch (boost) {
      case 'important-tenure':
        return 'seniority'
      case 'important-location':
        return 'location'
      case 'important-expertise':
        return 'expertise'
      case 'important-business-unit':
        return 'business unit'
      default:
        return ''
    }
  }

  return (
    <Modal
      {...props}
      onSave={onSave}
      onRequestClose={() => {
        resetAllBoosts()
        props.onRequestClose()
      }}
      size={Size.large}
      isSubmitDisabled={disableConfirmationButton}
      submitButton="Reject match"
      cancelButton="Cancel"
      title="Reject this match">
      <p>
        Are you sure you want to reject this match? This will separate the
        participants and the action cannot be undone. These participants can
        then be rematched with other participants in the &quot;Not yet
        matched&quot; table.
      </p>

      <Container direction="row" alignment="start" css={inputContainerStyle}>
        <Container
          direction="column"
          css={{
            width: '50%',
          }}>
          <div css={{ width: '100%' }}>
            <Paragraph bold>{participant1Name} Boost</Paragraph>
            <Input
              type="select"
              value={boostField1}
              getOptionLabel={(option: IOption) => option.label}
              name="partner1Boost"
              hideName
              options={props.possibleBoostOptions}
              onChange={onChangeBoost1}
              css={fieldCss}
            />
          </div>
          {showSpecificBoostInput1 && (
            <div css={{ width: '100%' }}>
              <Paragraph bold>
                Specify {specificBoostInputLabel(boostField1)}*
              </Paragraph>
              <Input
                type="select"
                required
                value={boostFieldChoice1}
                name="partner1BoostChoice"
                hideName
                options={getSpecificBoostOptions(
                  boostField1,
                  props.participantData?.participant_1_industry_years,
                )}
                onChange={onChangeBoostChoice1}
                css={fieldCss}
              />
            </div>
          )}
        </Container>
        <Container
          direction="column"
          css={{
            width: '50%',
          }}>
          <div css={{ width: '100%' }}>
            <Paragraph bold>{participant2Name} Boost</Paragraph>
            <Input
              type="select"
              value={boostField2}
              getOptionLabel={(option: IOption) => option.label}
              name="partner2Boost"
              hideName
              options={props.possibleBoostOptions}
              onChange={onChangeBoost2}
              css={fieldCss}
            />
          </div>
          {showSpecificBoostInput2 && (
            <div css={{ width: '100%' }}>
              <Paragraph bold>
                Specify {specificBoostInputLabel(boostField2)}*
              </Paragraph>
              <Input
                type="select"
                required
                value={boostFieldChoice2}
                name="partner2BoostChoice"
                hideName
                options={getSpecificBoostOptions(
                  boostField2,
                  props.participantData?.participant_2_industry_years,
                )}
                onChange={onChangeBoostChoice2}
                css={fieldCss}
              />
            </div>
          )}
        </Container>
      </Container>
    </Modal>
  )
}
