import { useCallback, useState } from 'react'

import { RejectConfirmationModal } from './Modals'

import { colors } from 'stylesheets/theme'
import { IMatchForReview } from './types'
import { useMentorshipExchangeDetailsContext } from './MentorshipExchangeDetailsContext'
import Spinner from 'images/icons/spinner.svg'
import { TableCellSecondaryButton } from 'components/Table/components/TableCell'
import Container from 'components/Container'

interface MatchReviewActionsCellProps {
  data: IMatchForReview
}

export default function MatchReviewActionsCell({
  data,
}: MatchReviewActionsCellProps): JSX.Element {
  const {
    boostList,
    tables,
    ajaxWithUpdates,
    matchesLoading,
    setMatchLoading,
    setMatchStoppedLoading,
  } = useMentorshipExchangeDetailsContext()
  const [openRejectModal, setOpenRejectModal] = useState(false)
  const onReject = useCallback(
    (params?: Record<string, any>, callback?: () => void) => {
      setMatchLoading(data.id.toString())
      ajaxWithUpdates(
        data.reject_match_url,
        params ?? {},
        'post',
        // onUpdate()
        () => {
          callback()
          setOpenRejectModal(false)
          setMatchStoppedLoading(data.id.toString())
        },
        // onError()
        () => {
          setOpenRejectModal(false)
          setMatchStoppedLoading(data.id.toString())
        },
      )
    },
    [data, ajaxWithUpdates, tables],
  )
  const onAccept = useCallback(
    (params?: Record<string, any>) => {
      setMatchLoading(data.id.toString())
      ajaxWithUpdates(
        data.accept_match_url,
        params ?? {},
        'post',
        () => {
          setMatchStoppedLoading(data.id.toString())
        },
        () => {
          setMatchStoppedLoading(data.id.toString())
        },
      )
    },
    [data, ajaxWithUpdates, tables],
  )

  return (
    <div>
      {matchesLoading[data.id] ? (
        <Spinner
          css={{
            width: 32,
            height: 32,
          }}
        />
      ) : (
        <Container
          css={{
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
          <TableCellSecondaryButton
            key={data.id}
            onClick={() => onAccept()}
            className="ignore-goji">
            Accept
          </TableCellSecondaryButton>
          <TableCellSecondaryButton
            className="ignore-goji"
            onClick={() => setOpenRejectModal(true)}
            css={{
              color: colors.red,
              borderColor: colors.red,
              '&:not([disabled]):focus, &:not([disabled]):hover': {
                color: colors.red,
                borderColor: colors.red,
              },
            }}>
            Reject
          </TableCellSecondaryButton>
        </Container>
      )}
      <RejectConfirmationModal
        isOpen={openRejectModal}
        onRequestClose={() => setOpenRejectModal(false)}
        onReject={onReject}
        possibleBoostOptions={boostList}
        participantData={data}
      />
    </div>
  )
}
