import { css } from '@emotion/react'
import useMediaQuery from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'
import { colors, sizes, spacings } from 'stylesheets/theme'

interface MentorshipApplicationWrapperProps {
  children: JSX.Element
  className?: string
}

const ApplicationFormWrapperStyle = css({
  minHeight: `calc(100vh - ${sizes.headerHeight})`,
  marginTop: sizes.headerHeight,
  padding: spacings.grid_gap_basis_num * 2,
  backgroundColor: colors.backgrounds.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  'mark:not(.optional)': {
    backgroundColor: colors.backgrounds.teal,
    color: colors.green_dark,
  },
  'mark.recommended': {
    color: colors.text.text_7c,
    fontSize: 18,
    lineHeight: 1.2,
    background: 'none',
  },
})

const MobileStyle = css({
  maxWidth: breakpoints.xs,
  justifyContent: 'space-between',
})

export default function MentorshipApplicationWrapper({
  children,
  className = '',
}: MentorshipApplicationWrapperProps): JSX.Element {
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)
  const style = [ApplicationFormWrapperStyle]
  if (isMobile) {
    style.push(MobileStyle)
  }
  return (
    <div css={style} className={className}>
      {children}
    </div>
  )
}
